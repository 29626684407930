import React, { useEffect, useState } from 'react';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import TimeGauge from './time-guage';

import { makeStyles } from '@material-ui/core/styles';
import { timeGaugeStyles } from '../../assets/jss/DailyReportStyle';

const useStyles = makeStyles(timeGaugeStyles);

export default function BGSegmentGauge(props) {
    const classes = useStyles();
    const { segmentData } = props;
    const [timeGauges, setTimeGauges] = useState([]);

    useEffect(() => {
        if (segmentData) {
            let updatedGauges = [];

            Object.keys(segmentData).forEach((key) => {
                const timeRange = segmentData[key];

                const {
                    timeInRange,
                    timeOutOfRange,
                    entryDate,
                    entryMonth,
                    entryHour,
                    numReadings,
                    numReadingsInRange,
                    numReadingsOutOfRange
                } = timeRange;

                // Get the percentage of time in range.
                const inRangePercentage = Math.floor(
                    (numReadingsInRange / numReadings) * 100
                );

                const outOfRangePercentage = Math.floor(
                    (numReadingsOutOfRange / numReadings) * 100
                );

                updatedGauges.push(
                    <tr key={key}>
                        <td>
                            <span>
                                {entryMonth} {entryDate}
                            </span>
                        </td>
                        <td>
                            <span>{entryHour}:00</span>
                        </td>
                        <td className={classes.gaugeEntry}>
                            <TimeGauge
                                inRangePercentage={inRangePercentage}
                                outOfRangePercentage={outOfRangePercentage}
                            />
                        </td>
                        <td> {timeInRange}</td>
                        <td> {timeOutOfRange}</td>
                        <td> {numReadings}</td>
                    </tr>
                );
            });

            setTimeGauges(updatedGauges);
        }
        // Given a time range, calculate the segments to fill in the gauge.
    }, [props]);

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <div className={classes.cardTitle}>
                    <span className={classes.cardTitleText}>
                        Hourly Time in Range
                    </span>
                </div>
            </CardHeader>

            <CardBody className={classes.cardBody}>
                <div className={classes.dailyMinMaxTableContainer}>
                    <table className={classes.dailyMinMaxTable}>
                        <thead>
                            <tr>
                                <th>
                                    <span>Date </span>
                                </th>
                                <th>
                                    <span>Hour</span>
                                </th>
                                <th>
                                    <span>Percentage of In Range Readings</span>
                                </th>
                                <th>
                                    <span>Number of Readings</span>
                                </th>
                                <th>
                                    <span>Minutes In Range</span>
                                </th>
                                <th>
                                    <span>Minutes Out of Range</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>{timeGauges}</tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    );
}
