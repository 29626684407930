import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

const SERENDIPITY_FIRESTORE_DATABASE =
    process.env.REACT_APP_FIREBASE_FIRESTORE_DATABASE;

// https://firebase.google.com/docs/projects/api-keys
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase, SERENDIPITY_FIRESTORE_DATABASE);
export const database = getDatabase(firebase);
export const auth = getAuth(firebase);

if (process.env.REACT_APP_SERENDIPITY_ENV === 'local') {
    connectFirestoreEmulator(db, 'localhost', 8081);

    connectDatabaseEmulator(database, 'localhost', 9000);

    connectAuthEmulator(auth, 'http://localhost:9099');

    console.log('Using Firebase Firestore emulator for client!');
    console.log('Using Firebase RTDB emulator for client!');
    console.log('Using Firebase Auth emulator for client!');
}
