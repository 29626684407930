/*eslint-disable*/
import React, { useState, useEffect, useCallback, useContext } from 'react';

// Auth Context
import { AuthContext } from 'lib/Auth';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-dashboard-pro-react/views/chartsStyle.js';
const useStyles = makeStyles(styles);

import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import DailyEntriesChart from 'components/Report/daily-entries-chart';
import Button from 'components/CustomButtons/Button.js';

import DailyDataTable from 'components/Report/daily-data-table';
import DailyRangeAverages from 'components/Report/daily-range-averages';
import BGSegmentGauge from 'components/Report/bg-segment-guage';

import { generateDailyReport } from 'lib/reporting/daily-report';
import ReportTimePicker from 'components/Serendipity/DateRangeSelector';
import { set } from 'date-fns';

export default function Reports(props) {
    const { userPreferences, updateUserContext } = useContext(AuthContext);
    const { nightscout, uid } = userPreferences;
    const { BG_TARGET_TOP, BG_TARGET_BOTTOM } = nightscout;
    const [reportLoading, setReportLoading] = useState(false);

    const DEFAULT_DATA = {
        dailyEntries: [],
        dailyAverage: [],
        dailyRangePercentages: {
            inRange: 0,
            belowRange: 0,
            aboveRange: 0
        },
        dailyTimeInRangePerHour: {},
        entries: []
    };

    const [reportData, setReportData] = useState(DEFAULT_DATA);

    const [reportDateTimeRange, setReportDateTimeRange] = useState(null);

    const getReportData = async () => {
        setReportLoading(true);
        setReportData(DEFAULT_DATA);
        const reportParams = {
            uid,
            reportDateTimeRange,
            targetHigh: BG_TARGET_TOP,
            targetLow: BG_TARGET_BOTTOM
        };

        try {
            const dailyReport = await generateDailyReport(reportParams);
            setReportData(dailyReport);
            setReportLoading(false);
        } catch (e) {
            setReportData(DEFAULT_DATA);
            setReportLoading(false);
            console.error('Unable to generate a daily report.');
        }
    };

    const handleDateTimeChange = (data) => {
        setReportDateTimeRange(data.dateRange);
    };

    const handleReportSubmit = () => {
        setReportLoading(true);
        getReportData();
        setReportLoading(false);
    };

    const classes = useStyles();
    return reportLoading ? (
        <div>
            {' '}
            <CircularProgress
                className={classes.inlineBlock}
                size={35}
                thickness={4}
            />
        </div>
    ) : (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <ReportTimePicker onChange={handleDateTimeChange} />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        color="danger"
                        size="lg"
                        onClick={() => {
                            handleReportSubmit();
                        }}
                        className={classes.saveButton}
                        disabled={reportLoading}
                    >
                        Generate Report{' '}
                    </Button>
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                        <GridItem>
                            <DailyEntriesChart
                                bgTargetBottom={BG_TARGET_BOTTOM}
                                bgTargetTop={BG_TARGET_TOP}
                                seriesData={reportData.dailyEntries}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <DailyDataTable
                                tableData={reportData.dailyAverage}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <DailyRangeAverages
                                seriesData={reportData.dailyRangePercentages}
                            />
                        </GridItem>
                    </GridContainer>
                    <BGSegmentGauge
                        segmentData={reportData.dailyTimeInRangePerHour}
                    />
                </GridItem>
            </GridContainer>
        </div>
    );
}
