import React from 'react';
import { Redirect } from 'react-router-dom';
import AdminLayout from 'layouts/Admin';
import Tos from '../components/Terms/Terms';
import { checkIsAuthenticated } from 'services/auth';

// Firebase
import { database } from '../services/firebase';
import { ref, onValue, update } from 'firebase/database';

export const getPreferences = function (user) {
    let userPrefRef = ref(database, 'preferences/' + user.uid);
    return new Promise((resolve) => {
        onValue(userPrefRef, (snapshot) => {
            const userPreferences = snapshot.val();
            resolve(userPreferences);
        });
    });
};

export const setPreferences = async (user, updates) => {
    const { key, val } = updates;

    let userPrefRef = ref(database, 'preferences/' + user.uid + `/${key}`);

    return update(userPrefRef, val)
        .then(() => {
            return true;
        })
        .catch((error) => {
            console.log('An error occurred saving user settings!', error);
            return false;
        });
};

export const setTos = async (tosAgree) => {
    const user = await checkIsAuthenticated();

    let userPrefRef = ref(
        database,
        'preferences/' + user.uid + '/serendipity/'
    );
    const tosUpdate = { tos: tosAgree, tosAcceptedDate: Date.now() };

    return update(userPrefRef, tosUpdate)
        .then(() => {
            return true;
        })
        .catch(() => {
            return false;
        });
};

export const getSubscriptionStatus = (preferences) => {
    if (!preferences) {
        return <Redirect to="/error" />;
    } else {
        const { serendipity } = preferences;

        if (!serendipity.tos) {
            return <Tos handleTosSubmit={setTos} />;
        } else {
            try {
                const { subscription } = serendipity;

                switch (subscription) {
                    case 'active':
                        return <AdminLayout />;
                    case 'free-tier':
                        return <AdminLayout />;
                    case 'inactive':
                        return <AdminLayout />;
                    default:
                        return <Redirect to="/pricing" />;
                }
            } catch (e) {
                return <Redirect to="/error" />;
            }
        }
    }
};

export const getDatabaseRef = () => {
    return database;
};
