import React, { useState } from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';

const ReportTimePicker = ({ onChange }) => {
    const [dateRange, setDateRange] = useState([null, null]);

    const handleDateRangeChange = (index, newDate) => {
        const newRange = [...dateRange];
        newRange[index] = newDate;
        setDateRange(newRange);
        onChange({ dateRange: newRange });
    };

    return (
        <GridContainer>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box p={2} maxWidth={600}>
                    <Grid container spacing={2}>
                        {/* Date Selection */}
                        <Grid item xs={6}>
                            <KeyboardDateTimePicker
                                label="Start Date & Time"
                                disableFuture
                                variant="inline"
                                format="MM/dd/yyyy HH:mm"
                                margin="normal"
                                value={dateRange[0]}
                                onChange={(dateTime) =>
                                    handleDateRangeChange(0, dateTime)
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                            />
                        </Grid>

                        {/* End Time Selection */}
                        <Grid item xs={6}>
                            <KeyboardDateTimePicker
                                label="End Date & Time"
                                disableFuture
                                variant="inline"
                                format="MM/dd/yyyy HH:mm"
                                margin="normal"
                                value={dateRange[1]}
                                onChange={(dateTime) =>
                                    handleDateRangeChange(1, dateTime)
                                }
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </MuiPickersUtilsProvider>
        </GridContainer>
    );
};

export default ReportTimePicker;
