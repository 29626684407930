/*eslint-disable*/
import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Parallax from 'components/Parallax/Parallax.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Footer from 'components/Footer/SerendipityFooter.js';

// sections for this page
import SectionPricing from './Sections/SectionPricing.js';
import { monthlyTierPricing } from 'assets/copy/LandingPageCopy.js';
import pricingStyle from 'assets/jss/material-kit-pro-react/views/pricingStyle.js';

const useStyles = makeStyles(pricingStyle);

export default function PricingPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                brand="Serendipity Bio"
                links={<HeaderLinks dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 25,
                    color: 'dark'
                }}
            />

            <Parallax image={'assets/img/bg2.jpg'} filter="dark" small>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            md={8}
                            sm={8}
                            className={classNames(
                                classes.mlAuto,
                                classes.mrAuto,
                                classes.textCenter
                            )}
                        >
                            <h1 className={classes.title}>
                                ${monthlyTierPricing} per month.
                            </h1>
                            <h4> Cancel anytime.</h4>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <SectionPricing />
                </div>
            </div>
            <Footer darkFont />
        </div>
    );
}
