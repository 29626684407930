import React, { useState, useEffect, useContext, useCallback } from 'react';

// Material UI components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// Serendipity components
import {
    GlucoseGraph,
    BGCard,
    DataSourceSelector,
    ServerStatusHeader
} from 'components/Serendipity';

import { useInterval } from 'hooks/useIntervalHook';

// Auth Context
import { AuthContext } from 'lib/Auth';

// Libs
import { getNightscoutEntries } from 'lib/nightscout';
import { DexcomClient } from 'lib/dexcom';
import { AbbottClient } from 'lib/abbott';

// Firebase
import { firebase, db } from '../../services/firebase';
import { getDatabase, ref, onValue } from 'firebase/database';
import {
    onSnapshot,
    query,
    collection,
    doc,
    orderBy,
    where
} from 'firebase/firestore';

const database = getDatabase(firebase);

export default function Dashboard() {
    const { userPreferences } = useContext(AuthContext);
    const { nightscout, abbott, uid, serendipity } = userPreferences;

    const abbottClient = new AbbottClient(abbott);
    const dexcomClient = new DexcomClient(nightscout);

    const { useV2Features } = serendipity;
    const { nsRefreshInterval, BG_TARGET_TOP, BG_TARGET_BOTTOM } = nightscout;

    const [preferences, setPreferences] = useState(userPreferences);

    const DEFAULT_ENTRY = {
        x: new Date(),
        y: 0,
        raw: {
            Value: 0,
            Trend: 'FLAT'
        }
    };

    const DEFAULT_FIRESTORE_TIMEOUT = 15 * 1000; // 15 seconds

    // Set up initial state for the dashboard.
    const [entries, setEntries] = useState([DEFAULT_ENTRY]);

    const DEFAULT_REFRESH_INTERVAL = 5 * 60 * 1000;

    const [fetchingEntries, setFetchingEntries] = useState(true);

    const [latestEntry, setLatestEntry] = useState(DEFAULT_ENTRY);

    const [priorEntry, setPriorEntry] = useState(DEFAULT_ENTRY);

    const [lastFetchTime, setLastFetchTime] = useState('Updating...');

    const [chartDataSource, setChartDataSource] = useState('NS');

    const getEntries = useCallback(async () => {
        let entries = [];
        let unsubscribe;

        // Each data source returns formatted entries for our dashboard.
        // Fix how we set the latest and prior entries.  This is sloppy.

        switch (chartDataSource) {
            case 'NS': {
                const nightscoutOpts = {
                    nsApiUrl: nightscout.base_url,
                    queryStartTime: new Date().getTime() - 24 * 60 * 60 * 1000,
                    count: 100
                };
                const nsEntries = await getNightscoutEntries(nightscoutOpts);

                if (nsEntries?.formattedEntries?.length > 0) {
                    entries = nsEntries.formattedEntries;
                    const numEntries = entries.length;
                    setLatestEntry(entries[numEntries - 1]);
                    setPriorEntry(entries[numEntries - 2]);
                }
                break;
            }
            case 'DX': {
                entries = await dexcomClient.getEntries();
                setLatestEntry(dexcomClient.latestEntry);
                setPriorEntry(dexcomClient.priorEntry);
                break;
            }
            case 'AB': {
                entries = await abbottClient.getEntries();
                setLatestEntry(abbottClient.latestEntry);
                setPriorEntry(abbottClient.priorEntry);
                break;
            }
            case 'SY': {
                const entriesRef = collection(doc(db, 'users', uid), 'entries');

                const currentDate = Date.now();
                const chartStartDate = new Date(
                    currentDate - 3 * 60 * 60 * 1000
                ); // 3 hours ago

                // Order entries in ascending order, the last entry in the list will be the latest reading.
                const entriesQuery = query(
                    entriesRef,
                    where('data.deviceCreateAt', '>=', chartStartDate),
                    orderBy('data.deviceCreateAt', 'asc')
                );

                // Subscribe to the query.
                unsubscribe = onSnapshot(
                    entriesQuery,
                    (querySnapshot) => {
                        if (querySnapshot.docs.length > 0) {
                            setFetchingEntries(false);
                            const newEntries = querySnapshot.docs.map(
                                (doc) => ({
                                    id: doc.id,
                                    data: doc.data(),
                                    x: doc.data().data.deviceCreateAt.toDate(),
                                    y: doc.data().data.glucoseValue
                                })
                            );

                            setEntries(newEntries);

                            // Latest entry breaks because we're not using the newly
                            // normalized data from the Firestore query.
                            if (newEntries.length > 0) {
                                setLatestEntry(
                                    newEntries[newEntries.length - 1].data.data
                                );
                                setPriorEntry(
                                    newEntries[newEntries.length - 2].data.data
                                );
                                setLastFetchTime(
                                    new Date(
                                        new Date().getTime() + nsRefreshInterval
                                    ).toLocaleTimeString()
                                );
                            }
                        }
                    },
                    (error) => {
                        console.error('Error getting documents: ', error);
                    }
                );

                let subscribeTimeout = setTimeout(() => {
                    unsubscribe();
                    setFetchingEntries(false);
                    if (entries.length === 0) {
                        setEntries([DEFAULT_ENTRY]);
                    }
                }, DEFAULT_FIRESTORE_TIMEOUT);

                return () => {
                    clearTimeout(subscribeTimeout);
                    unsubscribe();
                };
            }

            default:
                break;
        }

        if (entries.length > 0) {
            setEntries(entries);
            setLastFetchTime(
                new Date(
                    new Date().getTime() + nsRefreshInterval
                ).toLocaleTimeString()
            );
            setFetchingEntries(false);
        }

        setTimeout(() => {
            setFetchingEntries(false);
            setEntries([DEFAULT_ENTRY]);
        }, DEFAULT_FIRESTORE_TIMEOUT);
    }, [chartDataSource]);

    useInterval(async () => {
        getEntries();
    }, DEFAULT_REFRESH_INTERVAL);

    useEffect(() => {
        getEntries();
    }, [getEntries]);

    useEffect(() => {
        const updatePreferences = async () => {
            const userPrefRef = ref(database, 'preferences/' + uid);

            onValue(userPrefRef, (snapshot) => {
                const userPreferences = snapshot.val();
                setPreferences(userPreferences);
            });
        };

        updatePreferences();
    }, [uid]);

    const handleDataSourceSelect = (event) => {
        const { value } = event.target;
        // Clear entries whenever a data source is selected.
        setFetchingEntries(true);
        setEntries([DEFAULT_ENTRY]);
        setLatestEntry(DEFAULT_ENTRY);
        setPriorEntry(DEFAULT_ENTRY);
        setChartDataSource(value);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <DataSourceSelector
                                handleDataSourceSelect={handleDataSourceSelect}
                                chartDataSource={chartDataSource}
                                useAdvancedFeatures={useV2Features}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <ServerStatusHeader userPreferences={preferences} />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4} lg={3}>
                            <BGCard
                                loading={fetchingEntries}
                                entries={entries}
                                latestEntry={latestEntry}
                                priorEntry={priorEntry}
                                updateTime={lastFetchTime}
                                targetHigh={BG_TARGET_TOP}
                                targetLow={BG_TARGET_BOTTOM}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={8}>
                            <GlucoseGraph
                                data={entries}
                                loading={fetchingEntries}
                                targetHigh={BG_TARGET_TOP}
                                targetLow={BG_TARGET_BOTTOM}
                                handleDataSourceSelect={handleDataSourceSelect}
                                chartDataSource={chartDataSource}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </div>
    );
}
