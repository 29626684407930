import React, { useEffect, useState, useCallback } from 'react';

import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { makeStyles } from '@material-ui/core/styles';
import { dailyReportStyleBase } from '../../assets/jss/DailyReportStyle';

const useStyles = makeStyles(dailyReportStyleBase);

export default function DailyRangeAverages(props) {
    const classes = useStyles();

    const { chartColor } = dailyReportStyleBase;
    const { chartGreen, chartRed, chartOrange } = chartColor;
    const DEFAULT_CHART_OPTIONS = {
        title: {
            text: ''
        },
        series: [
            {
                pie: {
                    name: 'Percentage',
                    type: 'pie',
                    data: [
                        ['In Range Percentage', 0],
                        ['Below Range Percentage', 0],
                        ['Above Range Percentage', 0]
                    ],
                    innerSize: '60%',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f}%',
                        padding: 0
                    }
                }
            }
        ],
        credits: {
            enabled: false
        },
        accessibility: {
            enabled: false
        }
    };

    const [chartOptions, setChartOptions] = useState(DEFAULT_CHART_OPTIONS);

    // Given a set of entries, return the averages of readings in, out, and below range.
    const getDailyRangeAverage = async () => {
        const { seriesData } = props;

        if (seriesData) {
            const { inRange, belowRange, aboveRange } = seriesData;

            const updatedChartOptions = {
                ...DEFAULT_CHART_OPTIONS,
                chart: {
                    type: 'pie'
                },
                tooltip: {
                    valueSuffix: '%'
                },
                xAxis: {
                    lineWidth: 0
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            distance: '-35%'
                        },
                        innerSize: '60%'
                    }
                },
                series: [
                    {
                        name: '',
                        colorByPoint: true,
                        dataLabels: [
                            {
                                enabled: true,
                                distance: 30,
                                style: {
                                    fontSize: '1.2em',
                                    textOutline: 'none',
                                    opacity: 0.7
                                }
                            },
                            {
                                enabled: true,
                                distance: -35,
                                format: '{point.y}%',
                                style: {
                                    fontSize: '1.2em',
                                    textOutline: 'none',
                                    opacity: 0.7
                                }
                            }
                        ],
                        data: [
                            {
                                name: 'In Range',
                                y: inRange,
                                color: chartGreen,
                                sliced: true
                            },
                            {
                                name: 'Below Range',
                                y: belowRange,
                                color: chartOrange
                            },
                            {
                                name: 'Above Range',
                                y: aboveRange,
                                color: chartRed
                            }
                        ]
                    }
                ]
            };

            setChartOptions(updatedChartOptions);
        }
    };

    const buildDailyRangeChart = useCallback(() => {
        getDailyRangeAverage();
    });

    useEffect(() => {
        buildDailyRangeChart();
    }, [props]);

    return (
        <div>
            <Card>
                <CardHeader className={classes.cardHeader}>
                    <div className={classes.cardTitle}>
                        <span className={classes.cardTitleText}>
                            Daily Range Percentages
                        </span>
                    </div>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <div className={classes.dailyMinMaxTableContainer}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: '100%' } }}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
