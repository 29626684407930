import React, { useContext, useState, useEffect } from 'react';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// Custom component
import NSConfigSettings from './NsSettings';
import DexcomSettings from './DexcomSettings';
import FreeStyleSettings from './FreeStyleSettings';

// Auth Context
import { AuthContext } from 'lib/Auth';

// Firebase
import { firebase } from '../../services/firebase';
import { getDatabase, onValue, ref } from 'firebase/database';
const database = getDatabase(firebase);

export default function UserSettings() {
    const { userPreferences, updateUserContext } = useContext(AuthContext);
    const { nightscout, serendipity, uid } = userPreferences;
    const [userPref, setPreferences] = useState(userPreferences);

    useEffect(() => {
        const updatePreferences = async () => {
            let userPrefRef = ref(database, 'preferences/' + uid);
            onValue(userPrefRef, (snapshot) => {
                const userPreferences = snapshot.val();

                setPreferences(userPreferences);
            });
        };

        updatePreferences();
    }, [uid]);

    // TODO: Instead of Task elements, use the Form label elements you create in the NsConfig file.

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={8} lg={10}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8} lg={12}>
                        <NSConfigSettings
                            handleContextUpdate={updateUserContext}
                            nightscoutContext={nightscout}
                            serendipityContext={serendipity}
                            userPreferences={userPref}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={12}>
                        <DexcomSettings
                            handleContextUpdate={updateUserContext}
                            nightscoutContext={nightscout}
                            serendipityContext={serendipity}
                            userPreferences={userPref}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8} lg={12}>
                        <FreeStyleSettings
                            handleContextUpdate={updateUserContext}
                            userPreferences={userPref}
                        />
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    );
}
