// Firebase
import { db } from '../../services/firebase';
import {
    query,
    collection,
    doc,
    orderBy,
    where,
    getDocs
} from 'firebase/firestore';

class DailyReporter {
    constructor(opts) {
        const { uid, reportDateTimeRange, targetHigh, targetLow } = opts;

        this.uid = uid;

        this.entries = [];

        this.REPORT_START = new Date(reportDateTimeRange[0]);
        this.REPORT_END = new Date(reportDateTimeRange[1]);

        this.TARGET_LOW = targetLow;
        this.TARGET_HIGH = targetHigh;

        this.DAILY_AVERAGE = 0;

        // These data structures need to follow the API that highcharts utilizes

        this.DAILY_TREND_MAP = {
            sunday: { high: -1, low: -1, average: -1 },
            monday: { high: -1, low: -1, average: -1 },
            tuesday: { high: -1, low: -1, average: -1 },
            wednesday: { high: -1, low: -1, average: -1 },
            thursday: { high: -1, low: -1, average: -1 },
            friday: { high: -1, low: -1, average: -1 },
            saturday: { high: -1, low: -1, average: -1 }
        };

        this.DAILY_AVERAGE_MAP = {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: []
        };

        this.HOURLY_TREND_MAP = {};

        this.HOULRY_TREND_AVERAGE_MAP = {};

        this.WEEKLY_TREND_MAP = {};
    }

    getReportEntries = async () => {
        const entriesRef = collection(doc(db, 'users', this.uid), 'entries');

        const entriesQuery = query(
            entriesRef,
            where('data.deviceCreateAt', '>=', this.REPORT_START),
            where('data.deviceCreateAt', '<=', this.REPORT_END),
            orderBy('timestamp', 'asc')
        );

        // console.log(chartStartDate);
        // Subscribe to the query.
        const entrySnapshot = await getDocs(entriesQuery);

        let reportEntries = [];
        entrySnapshot.forEach((doc) => {
            const { deviceCreateAt, glucoseValue } = doc.data().data;
            reportEntries.push({
                x: deviceCreateAt.toDate(),
                y: glucoseValue
            });
        });

        this.entries = reportEntries;
        return this.entries;
    };
    /**
     *
     *
     * @param {*} opts
     */
    getDailyReport = async () => {
        const reportEntries = await this.getReportEntries();

        const dailyReport = {
            dailyEntries: reportEntries,
            dailyAverage: await this.getDailyAverage(reportEntries),
            dailyRangePercentages: await this.getDailyRangePercentages(
                reportEntries
            ),
            dailyTimeInRangePerHour: await this.getDailyTimeInRangePerHour()
        };

        return dailyReport;
    };

    // Given a set of entries, return the daily averages for each day of the week.
    getDailyAverage = async (entries) => {
        // Group data by date
        const groupedData = entries.reduce((acc, item) => {
            const timestamp = new Date(item.x);
            const dateKey = timestamp.toISOString().split('T')[0]; // Extract the date part
            if (!acc[dateKey]) {
                acc[dateKey] = [];
            }
            acc[dateKey].push(item.y);
            return acc;
        }, {});

        // Calculate daily average, min, and max
        const dailyAverageMap = Object.keys(groupedData).map((date) => {
            const values = groupedData[date];
            const sum = values.reduce((total, value) => total + value, 0);
            const avg = sum / values.length;
            const min = Math.min(...values);
            const max = Math.max(...values);

            return {
                date: date,
                average: avg.toFixed(2),
                min: min,
                max: max
            };
        });

        return dailyAverageMap;
    };

    getDailyRangePercentages = async (entries) => {
        const rangeMap = { inRange: 0, belowRange: 0, aboveRange: 0 };

        const rangeMapAverages = { inRange: 0, belowRange: 0, aboveRange: 0 };

        entries.forEach((entry) => {
            const { y } = entry;
            if (y >= this.TARGET_LOW && y <= this.TARGET_HIGH) {
                rangeMap.inRange++;
            } else if (y < this.TARGET_LOW) {
                rangeMap.belowRange++;
            } else {
                rangeMap.aboveRange++;
            }
        });

        rangeMapAverages.inRange = Math.round(
            (rangeMap.inRange / entries.length) * 100
        );
        rangeMapAverages.belowRange = Math.round(
            (rangeMap.belowRange / entries.length) * 100
        );
        rangeMapAverages.aboveRange = Math.round(
            (rangeMap.aboveRange / entries.length) * 100
        );

        return {
            inRange: rangeMapAverages.inRange,
            belowRange: rangeMapAverages.belowRange,
            aboveRange: rangeMapAverages.aboveRange
        };
    };

    getDailyTimeInRangePerHour = async () => {
        const entries = this.entries;

        const MAX_READING_DURATION = 5; // Set a max limit of 5 minutes between readings.

        const hourlyStats = {};

        for (let i = 1; i < entries.length; i++) {
            const current = entries[i];
            const previous = entries[i - 1];

            const previousHour = new Date(previous.x)
                .toISOString()
                .slice(0, 13);

            // We may have readings that are not in order.  Skip these.
            if (current.x < previous.x) {
                continue;
            }

            // We assume that a maximum of 5 minutes goes between readings.  Anything less is acceptable.
            const readingDuration = Math.floor(
                (current.x - previous.x) / 1000 / 60
            ); // Get the time difference in minutes

            const timeDifference = Math.min(
                readingDuration,
                MAX_READING_DURATION
            );

            // If no hourly stats entry exists, create a new one.
            if (!hourlyStats[previousHour]) {
                hourlyStats[previousHour] = {
                    timeInRange: 0,
                    timeOutOfRange: 0,
                    numReadings: 0,
                    entryDate: new Date(previous.x).getDate(),
                    entryMonth: new Date(previous.x).toLocaleString('default', {
                        month: 'long'
                    }),
                    entryHour: new Date(previous.x).getHours(),
                    numReadingsInRange: 0,
                    numReadingsOutOfRange: 0
                };
            }

            if (
                previous.y >= this.TARGET_LOW &&
                previous.y <= this.TARGET_HIGH
            ) {
                hourlyStats[previousHour].timeInRange += timeDifference; // Add the time difference to the time in range
                hourlyStats[previousHour].numReadingsInRange++;
            } else {
                hourlyStats[previousHour].timeOutOfRange += timeDifference; // Add the time difference to the time out of range
                hourlyStats[previousHour].numReadingsOutOfRange++;
            }

            hourlyStats[previousHour].numReadings++;
        }

        return hourlyStats;
    };
}

export const generateDailyReport = async (opts) => {
    const dailyReporter = new DailyReporter(opts);
    const dailyReport = await dailyReporter.getDailyReport();

    return dailyReport;
};
