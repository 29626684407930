/*eslint-disable*/
import React from 'react';

import TermsPage from '../../components/Terms/Terms';
import Welcome from '../../components/Welcome/Welcome';

export default function PaymentSuccessfulPage() {
    const tosAccepted = new URLSearchParams(window.location.search).get('tos');

    return (
        <div>
            <Welcome />
            {tosAccepted ? <div /> : <TermsPage />};
        </div>
    );
}
