import React, { useEffect, useState } from 'react';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Moment from 'moment';
import MomentTimeZone from 'moment-timezone';

import { makeStyles } from '@material-ui/core/styles';
import {
    dailyReportStyleBase,
    dailyEntriesStyle
} from '../../assets/jss/DailyReportStyle';

const useStyles = makeStyles(dailyReportStyleBase);
const { lowThreshold, highThreshold, inRange } = dailyEntriesStyle;

export default function DailyEntriesChart(props) {
    window.moment = Moment;
    MomentTimeZone();

    const classes = useStyles();

    const { bgTargetBottom, bgTargetTop } = props;

    const DEFAULT_LINE_CHART_OPTIONS = {
        title: {
            text: ''
        },
        xAxis: { type: 'datetime' },
        yAxis: { title: { text: 'mg/dL' } },
        plotOptions: {
            series: {
                marker: {
                    enabled: true
                }
            }
        },
        series: [
            {
                type: 'scatter',
                name: 'Glucose Readings',
                data: [],
                zones: [
                    { value: bgTargetBottom, color: lowThreshold },
                    { value: bgTargetTop, color: inRange },
                    { color: highThreshold }
                ]
            }
        ],
        credits: {
            enabled: false
        },
        legend: { enabled: false },
        accessibility: {
            enabled: false
        }
    };

    const [chartOptions, setChartOptions] = useState(
        DEFAULT_LINE_CHART_OPTIONS
    );

    useEffect(() => {
        // Timezone is needed to handle local time
        const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const { seriesData, bgTargetBottom, bgTargetTop } = props;

        setChartOptions({
            series: [
                {
                    data: seriesData,
                    zones: [
                        { value: bgTargetBottom, color: lowThreshold },
                        { value: bgTargetTop, color: inRange },
                        { color: highThreshold }
                    ]
                }
            ],
            time: {
                timezone: timeZoneString,
                useUTC: true
            }
        });
    }, [props]);

    return (
        <div>
            <Card>
                <CardHeader className={classes.cardHeader}>
                    <div className={classes.cardTitle}>
                        <span className={classes.cardTitleText}>
                            Daily Entries
                        </span>
                        <div
                            style={{
                                float: 'right',
                                marginLeft: '15px',
                                marginTop: '-5px'
                            }}
                        ></div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotRed} />
                            <span>Out of Range</span>
                        </div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotGreen} />
                            <span>
                                Target Range: {props.bgTargetBottom} and{' '}
                                {props.bgTargetTop}
                            </span>
                        </div>
                    </div>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <div className={classes.dailyMinMaxTableContainer}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: '100%' } }}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
