import React from 'react';

// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import { makeStyles } from '@material-ui/core/styles';
import styles from './GlucoseGraphStyle.js';

// Victory chart components
import {
    VictoryAxis,
    VictoryScatter,
    VictoryChart,
    createContainer,
    VictoryLabel
} from 'victory';

// Serendipity Libs
import serendipityVictoryTheme from './serendipityVictoryTheme';

const useStyles = makeStyles(styles);

export default function GlucoseGraph(props) {
    const classes = useStyles();

    const SerendipityVictoryContainer = createContainer('voronoi', 'cursor');

    const { loading, data } = props;

    return loading || data.length === 0 ? (
        <div
            className={
                classes.circularProgress + ' ' + classes.victoryContainer
            }
        >
            <Card>
                <CardBody
                    style={{
                        height: '400px'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%'
                        }}
                    >
                        <CircularProgress
                            className={classes.inlineBlock}
                            size={35}
                            thickness={4}
                        />
                    </div>
                </CardBody>
            </Card>
        </div>
    ) : (
        <div style={{ width: '100%', height: '100%' }}>
            <Card>
                <CardHeader style={{ paddingBottom: '0' }}>
                    <div style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                        <span
                            style={{
                                textAlign: 'left',
                                fontSize: '20px',
                                fontWeight: '600'
                            }}
                        >
                            Glucose Level (mg/dL)
                        </span>

                        <div
                            style={{
                                float: 'right',
                                marginLeft: '15px',
                                marginTop: '-5px'
                            }}
                        ></div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotRed} />
                            <span>Out of Range</span>
                        </div>
                        <div style={{ float: 'right', marginLeft: '5px' }}>
                            <span className={classes.legendDotGreen} />
                            <span>
                                Target Range: {props.targetLow} and{' '}
                                {props.targetHigh}
                            </span>
                        </div>
                    </div>
                </CardHeader>
                <div>
                    <VictoryChart
                        theme={serendipityVictoryTheme}
                        domain={{
                            x: [
                                new Date(
                                    Math.max(...data.map((d) => d.x)) -
                                        3 * 60 * 60 * 1000
                                ), // 3 hours ago
                                new Date(Math.max(...data.map((d) => d.x)))
                            ], // latest timestamp
                            y: [80, 350]
                        }}
                        scale={{ x: 'time' }}
                        containerComponent={
                            <SerendipityVictoryContainer
                                cursorDimension="x"
                                cursorLabel={({ datum }) =>
                                    `Time: ${datum.x.getHours()}:${datum.x.getMinutes()} \nSG: ${datum.y.toPrecision(
                                        5
                                    )}`
                                }
                                cursorLabelComponent={
                                    <VictoryLabel
                                        textAnchor={
                                            ({ datum }) =>
                                                datum.x >=
                                                new Date(
                                                    Math.max(
                                                        ...data.map((d) => d.x)
                                                    )
                                                ) -
                                                    30 * 60 * 1000
                                                    ? 'end' // Align left if near right edge
                                                    : 'start' // Align right otherwise
                                        }
                                        dx={
                                            ({ datum }) =>
                                                datum.x >=
                                                new Date(
                                                    Math.max(
                                                        ...data.map((d) => d.x)
                                                    )
                                                ) -
                                                    30 * 60 * 1000
                                                    ? -20 // Shift left when near right edge
                                                    : 20 // Shift right otherwise
                                        }
                                        dy={-10} // Moves label above the cursor
                                        style={[
                                            {
                                                fontSize: 16,
                                                fontWeight: 300,
                                                fill: '#333'
                                            }
                                        ]}
                                    />
                                }
                            />
                        }
                    >
                        <VictoryAxis />
                        <VictoryAxis dependentAxis />
                        <VictoryScatter
                            data={data}
                            x={(data) => data.x}
                            y="y"
                            domainPadding={0}
                            // samples={data.length}
                            // size={1.5}
                            size={({ active }) => (active ? 5 : 2)}
                            style={{
                                data: {
                                    fill: ({ datum }) => {
                                        if (datum.y <= props.targetLow) {
                                            return '#FC6045';
                                        } else if (
                                            datum.y >= props.targetHigh
                                        ) {
                                            return '#FC6045';
                                        } else {
                                            return '#0DBB53';
                                        }
                                    }
                                }
                            }}
                        />
                    </VictoryChart>
                </div>
                {/* <CardBody style={{ height: '350px' }}>
                </CardBody> */}
            </Card>
        </div>
    );
}
