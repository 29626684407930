import Reports from 'views/Reports/Reports.js';
import Dashboard from 'views/Dashboard/Dashboard.js';
import Settings from 'views/SettingsPage/SettingsPage';
import BillingSettings from 'views/SettingsPage/BillingSettings';
import LogoutPage from 'views/LogoutPage/LogoutPage';
import SupportPage from 'views/SupportPage/SupportPage';
import ErrorPage from 'views/ErrorPage/ErrorPage';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import CreditCard from '@material-ui/icons/CreditCard';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ContactSupport from '@material-ui/icons/ContactSupport';

var dashRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        rtlName: '',
        icon: DashboardIcon,
        component: Dashboard,
        layout: '/admin'
    },
    {
        path: '/reports',
        name: 'Reports',
        rtlName: '',
        icon: BarChartIcon,
        component: Reports,
        layout: '/admin'
    },
    {
        path: '/settings',
        name: 'Settings',
        rtlName: '',
        icon: SettingsIcon,
        component: Settings,
        layout: '/admin'
    },
    {
        path: '/billing',
        name: 'Billing',
        rtlName: '',
        icon: CreditCard,
        component: BillingSettings,
        layout: '/admin'
    },
    {
        path: '/support',
        name: 'Support',
        rtlName: '',
        icon: ContactSupport,
        component: SupportPage,
        layout: '/admin'
    },
    {
        path: '/logout',
        name: 'Sign Out',
        rtlName: '',
        icon: ExitToApp,
        component: LogoutPage,
        layout: '/admin'
    },
    {
        path: '/error',
        component: ErrorPage
    }
];
export default dashRoutes;
