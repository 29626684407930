import React, { useEffect, useState } from 'react';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import { makeStyles } from '@material-ui/core/styles';
import { dailyReportStyleBase } from '../../assets/jss/DailyReportStyle';

const useStyles = makeStyles(dailyReportStyleBase);
export default function DailyDataTable(props) {
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (props.tableData) {
            setTableData(props.tableData);
        }
    }, [props]);

    return (
        <div>
            <Card>
                <CardHeader className={classes.cardHeader}>
                    <div className={classes.cardTitle}>
                        <span className={classes.cardTitleText}>
                            Daily Min and Max
                        </span>
                    </div>
                </CardHeader>
                <CardBody className={classes.cardBody}>
                    <div className={classes.dailyMinMaxTableContainer}>
                        <table className={classes.dailyMinMaxTable}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Average</th>
                                    <th>Min</th>
                                    <th>Max</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((stat, index) => (
                                    <tr key={index}>
                                        <td>{stat.date}</td>
                                        <td>{stat.average}</td>
                                        <td>{stat.min}</td>
                                        <td>{stat.max}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
